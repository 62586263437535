export default defineI18nConfig(() => ({
    legacy: false,
    datetimeFormats: {
        nl: {
            dateLong: {
                month: 'long', day: 'numeric', year: 'numeric'
            },
        },
        en: {
            dateLong: {
                month: 'long', day: 'numeric', year: 'numeric'
            }
        }
    },
    numberFormats: {
        nl: {
            currency: {
                style: 'currency', currency: 'EUR', notation: 'standard'
            }
        },
        en: {
            currency: {
                style: 'currency', currency: 'EUR', notation: 'standard'
            }
        },
    },
    locale: 'nl',
    globalInjection: true,
    inheritLocale: true,
    fallbackLocale: 'nl',
    missingWarn: false,
    fallbackWarn: false,
}));
